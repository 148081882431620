import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useMobileStore = defineStore('mobile', () => {
    const isMobileNavOpen = ref(false);

    const toggleMobileNav = () => {
        isMobileNavOpen.value = !isMobileNavOpen.value;
    };

    const closeMobileNav = () => {
        isMobileNavOpen.value = false;
    };

    return {
        isMobileNavOpen,
        toggleMobileNav,
        closeMobileNav,
    };
}); 